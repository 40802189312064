<template>
    <div class="animated fadeIn">
        <CCard>
            <CCardHeader><font-awesome-icon icon="list-ol"/> Modules</CCardHeader>
            <CCardBody>
                <CDataTable
                    :items="module_lists"
                    :fields="module_lists_fields"
                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                    :items-per-page="10"
                    border
                    items-per-page-select
                    :loading="this.table_loading"
                    sorter
                    pagination
                >
                <template slot="no-items-view" v-if="this.table_loading">
                    <center>
                        <CSpinner
                            style="width:4rem;height:4rem;"
                            color="info"
                        />
                    </center>
                </template>
                <template #icon="{item}">
                    <td>
                        <center>
                            <CIcon :name="item.icon" size="xl" />
                        </center>
                    </td>
                </template>
                <template #description="{item}">
                    <td>
                        {{item.description ? item.description : ''}}
                    </td>
                </template>
                <template #Action="{item}">
                    <td>
                        <router-link :to="{path: `/${$store.getters['getOrganizationName']}/administrator/module/${item.id}`}">
                            <CButton 
                                color="info" 
                                shape="pill" 
                                size="sm"
                            >
                                <font-awesome-icon icon="cog"/> Manage
                            </CButton>
                        </router-link>
                    </td>
                </template>
                <template #type="{item}">
                    <td>
                        <CBadge :color="getColorStatus(item.type)">
                            {{item.type.toUpperCase()}}
                        </CBadge>
                    </td>
                </template>
                <template #status="{item}">
                    <td>
                        <CBadge :color="getColorStatus(item.status)">
                            {{item.status}}
                        </CBadge>
                    </td>
                </template>
                </CDataTable>
            </CCardBody>
        </CCard>   
    </div>
</template>
<script>
export default {
    name: 'ModuleLists',
    data () {
        return {
            table_loading: true,
            module_lists: [],
        }
    },
    created() {
        this.getModules();
        this.$emit('activeTab', 0);
    },
    computed: {
            module_lists_fields: function () {
            let custom_fields = [
                { key: 'icon', sorter: false},
                { key: 'name', label: 'Name'},
                { key: 'url', label: 'Link'},
                { key: 'group' },
                { key: 'description' },
                { key: 'type' },
                { key: 'status' },
            ]
            if(this.$store.getters.can('view-module')) {
                custom_fields.push({ key: 'Action', sorter: false})
            }
            return custom_fields;
        }
    },
    methods: {
        getModules: function() {
            this.$Progress.start()
            axios.get('/modules/list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    for (let i = 0; i < response.data.data.length; i++) {
                        let row = {
                                id:  response.data.data[i].id, 
                                icon:  response.data.data[i].icon, 
                                group:  response.data.data[i].group.name, 
                                name:  response.data.data[i].name, 
                                url:  response.data.data[i].url, 
                                description: response.data.data[i].description, 
                                type: response.data.data[i].type, 
                                status: response.data.data[i].deleted_at ? 'Archived' : 'Active', 
                        }
                        this.module_lists.push(row)
                    }
                    this.table_loading = false;
                    this.$Progress.finish()
                }
            })
        },
    }
}
</script>